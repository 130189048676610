"use client";

import { BASE_COLORS } from "@/theme";
import { Box, Button, CenterBox, Text } from "../styled";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BouncyModal } from "../styled/BouncyModal";
import { useModal } from "@/hooks/useModal";
import { FollowableType } from "@/gql/graphql";
import { useFollow } from "@/hooks/useFollow";
import toast from "react-hot-toast";
import { useUnFollow } from "@/hooks/useUnFollow";
import { useUser } from "@/hooks/useUser";
import { Spinner } from "../Spinner";
import Image from "next/image";
import styled from "styled-components";

type FollowBtnProps = {
  name: string;
  follwableId: string;
  followableType: FollowableType;
  alreadyFollowing: boolean;
  loading: boolean;
  setFollowerCount?: Dispatch<SetStateAction<number>>;
  followerCount: number;
  border?: boolean;
  keyword?:boolean;
};

export const FollowBtn = ({
  name,
  followableType,
  follwableId,
  alreadyFollowing,
  loading,
  setFollowerCount,
  followerCount,
  border,
  keyword
}: FollowBtnProps) => {
  const [isfollowing, setIsFollowing] = useState(alreadyFollowing);
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const { isOpen, toggleModal } = useModal();
  const { follow } = useFollow();
  const { unfollow } = useUnFollow();
  const { refetch } = useUser();

  useEffect(() => {
    setIsFollowing(alreadyFollowing);
  }, [alreadyFollowing]);

  const handleFollow = async () => {
    setIsActionInProgress(true);
    try {
      const { data } = await follow({
        variables: {
          input: {
            followableId: follwableId,
            followableType: followableType,
          },
        },
      });
      setIsFollowing(true);
      setFollowerCount && setFollowerCount(followerCount + 1);
      toast.success(`Followed ${name}`);
      refetch();
    } catch (error: any) {
      let errorMessage = "An unexpected error occurred.";
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        errorMessage = error.graphQLErrors[0].message;
      }
      toast.error(`Error: ${errorMessage}`);
      console.error(error);
    } finally {
      setIsActionInProgress(false);
    }
  };

  const handleUnFollow = async () => {
    setIsActionInProgress(true);
    try {
      const { data } = await unfollow({
        variables: {
          input: {
            followableId: follwableId,
            followableType: followableType,
          },
        },
      });
      setIsFollowing(false);
      setFollowerCount && setFollowerCount(followerCount - 1);
      toggleModal();
      toast.success(`Unfollowed ${name}`);
      refetch();
    } catch (error: any) {
      let errorMessage = "An unexpected error occurred.";
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        errorMessage = error.graphQLErrors[0].message;
      }
      toast.error(`Error: ${errorMessage}`);
      console.error(error.message);
    } finally {
      setIsActionInProgress(false);
    }
  };

  return (
    <CenterBox>
      <StyledFollowHoverBtn
        p="xs"
        color={isfollowing ? "primary" : "white"}
        backgroundColor="transparent"
        onClick={isfollowing ? toggleModal : handleFollow}
      >
        {isActionInProgress || loading ? (
          <Box height={20} width={20}>
            <Spinner color={"primary"} />
          </Box>
        ) : isfollowing ? (
          border ? (
            <Box>
              <Button
                backgroundColor={"white"}
                borderRadius={"full"}
                border={`1px solid ${BASE_COLORS.primary}`}
                px={"md"}
                py="xs"
              >
                <Text color="primary">Following</Text>
              </Button>
            </Box>
          ) : (
            <Image
              width={20}
              height={20}
              alt="check"
              src={"/assets/icons/checkIcon.svg"}
            />
          )
        ) : border ? (
          <Box>
            <Button
              backgroundColor={"primary"}
              borderRadius={"full"}
              border={`1px solid ${BASE_COLORS.primary}`}
              px={"md"}
              py="xs"
            >
          {keyword ? <Text color="white">Follow keyword</Text> : <Text color="white"> Follow </Text>}
              <Image
                src={"/assets/icons/plusIcon.svg"}
                width={10}
                height={10}
                alt="check"
              />
            </Button>
          </Box>
        ) : (
          <Image
            width={20}
            height={20}
            alt="check"
            src={"/assets/icons/plusIconV2.svg"}
          />
        )}
      </StyledFollowHoverBtn>

      <BouncyModal
        isOpen={isOpen}
        onClose={toggleModal}
        height={"fit-content"}
        width={["90vw", "25vw"]}
        borderRadius="20px"
      >
        <Box py={"3xl"} px={"md"}>
          <Text size={["14px", "20px"]} weight="bold" pb={"md"}>
            Unfollow <Text>@{name}?</Text>
          </Text>
          <Text size={["14px", "16px"]} color="grey" pb={"2xl"}>
            Their posts will no longer show up in your Following timeline. You can
            still view their profile, unless their posts are protected.
          </Text>
          <Box gap={15}>
            <Button
              border={`1px solid ${BASE_COLORS.primary}`}
              borderRadius="2xl"
              bg={"primary"}
              px="md"
              py="xs"
              color="white"
              onClick={handleUnFollow}
              disabled={isActionInProgress}
            >
              {isActionInProgress ? (
                <Box height={20} width={20}>
                  <Spinner color="white" />
                </Box>
              ) : (
                <Text size={["14px", "16px"]} py={"xs"} color="black">
                  Unfollow
                </Text>
              )}
            </Button>
            <Button
              border={`1px solid ${BASE_COLORS.primary}`}
              borderRadius="2xl"
              bg={"white"}
              px="md"
              py="xs"
              color="primary"
              onClick={toggleModal}
              disabled={isActionInProgress}
            >
              <Text size={["14px", "16px"]} py={"xs"} color="black">
                Cancel
              </Text>
            </Button>
          </Box>
        </Box>
      </BouncyModal>
    </CenterBox>
  );
};

export const StyledFollowHoverBtn = styled(CenterBox)`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    border-radius: 50px;
    background-color: ${BASE_COLORS.primaryLight};

    /* transform: scale(0.9); */
  }
`;
