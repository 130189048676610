import { useWhoToFollow } from "@/hooks/useWhoToFollow";
import { Column, Box, Row } from "../styled";
import { ProfileAndNotification } from "./Components/ProfileAndNotification";
import { Subscribe } from "./Components/Subscribe";
import { WhoToFollow } from "./Components/WhoToFollow";
import { useUserV2 } from "@/hooks/useUserV2";
import { SocialMediaLogos } from "./Components/SocialMediaLogos";


export const RightSideBar = () => {
  const { isLoggedIn } = useUserV2();
  const { data } = useWhoToFollow();

  const followLength = data?.whoToFollow?.length ?? 0;

  return (
    <Column px="lg" py="2xl" gap="2xl" overflowY={"hidden"}>
      <ProfileAndNotification isLoggedIn={isLoggedIn} />
      <Subscribe />
     
      {isLoggedIn && followLength > 0 ? <WhoToFollow /> : null}
      <SocialMediaLogos/>
    </Column>
  );
};
