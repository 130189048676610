import {
  Box,
  CenterBox,
  Column,
  PrimaryHoverBox,
  Row,
  Text,
} from "@/components/styled";
import { FollowableType } from "@/gql/graphql";
import { useUser } from "@/hooks/useUser";
import { BASE_COLORS } from "@/theme";
import { useState } from "react";
import { useRouter } from "next/navigation";
import { FollowBtn } from "@/components/FollowBtn";

type WhoToFollowCardProps = {
  name: string;
  image: string;
  following: number;
  id: string;
};

export const WhoToFollowCardV2 = ({
  name,
  image,
  following,
  id,
}: WhoToFollowCardProps) => {
  const { data, loading } = useUser();
  const router = useRouter();
  const [followerCount, setFollowerCount] = useState(following);
  const alreadyFollowing = data?.me.follows.find((item) => {
    return (
      item.followableId === id &&
      item.followableType === FollowableType.Organization
    );
  });

  const handleCardClick = (e: React.MouseEvent) => {
    router.push(`/organization/${data?.me.school?.alias}/${id}/overview`);
  };

  const handleFollowBtnClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <PrimaryHoverBox
      hoverBackgroundColor={BASE_COLORS.primaryLightTransparent}
      flexDirection={"row"}
      width="100%"
      height="fit-content"
      border={`1px solid ${BASE_COLORS.primary}`}
      borderRadius={"xl"}
      py={"sm"}
      style={{ cursor: "pointer" }}
      onClick={handleCardClick}
    >
      <Column alignItems={"center"} width={"100%"} >
        <Box height={"45px"}>
          <Box
            width="45px"
            height="45px"
            border={`1px solid ${BASE_COLORS.grey}`}
            borderRadius="full"
            backgroundSize="contain"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            style={{
              backgroundImage: `url('${image}')`,
              cursor: "pointer",
            }}
          ></Box>
        </Box>
        <Box gap={2}>
        <Box alignItems={"center"} justifyContent={"center"}  width={"100%"} >
          <Text color="primary" size={12} >
            {followerCount}{" "}
            {followerCount > 1 || followerCount === 0
              ? "Followers"
              : "Follower"}
          </Text>
          
        </Box>

        <Column alignItems={"center"}>
          <Text size={12} style={{ cursor: "pointer" }}>
            {name}
          </Text>
        </Column>
        <Box onClick={handleFollowBtnClick} justifyContent={"center"} >
            <FollowBtn
              name={name}
              follwableId={id}
              followableType={FollowableType.Organization}
              alreadyFollowing={Boolean(alreadyFollowing)}
              loading={loading}
              setFollowerCount={setFollowerCount}
              followerCount={followerCount}
              border={true}
            
            />
          </Box>
          </Box>
      </Column>
    </PrimaryHoverBox>
  );
};
