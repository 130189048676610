import { graphql } from "@/gql";
import { useMutation } from "@apollo/client";

export const UNFOLLOW_DOCUMENT = graphql(`
  mutation Unfollow($input: FollowActivityInput!) {
    unfollow(input: $input) {
      followableId
    }
  }
`);
export const useUnFollow = () => {
  const [unfollow, { data, loading, error }] = useMutation(UNFOLLOW_DOCUMENT);
  return {
    unfollow,
    loading,
    error,
    data,
  };
};
