// NotificationBox.tsx
import React, { useRef, useEffect } from 'react';
import Image from "next/image";
import { Box, Text } from "@/components/styled";
import { BASE_COLORS } from "@/theme";
import styled from "styled-components";

type NotificationBoxProps = {
  unreadNotifications: number;
  router: any;
};

export const NotificationBox: React.FC<NotificationBoxProps> = ({ unreadNotifications, router }) => {
  const prevUnreadNotifications = useRef<number>(unreadNotifications);
  const notificationSoundRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (!notificationSoundRef.current) {
      notificationSoundRef.current = new Audio("/assets/sounds/notification-sound.wav");
    }

    if (unreadNotifications > prevUnreadNotifications.current) {
      notificationSoundRef.current.play();
    }

    prevUnreadNotifications.current = unreadNotifications;
  }, [unreadNotifications]);

  return (
    <NotificationHoverBox
      borderRadius={"full"}
      position={"relative"}
      width={"60px"}
      height={"60px"}
      justifyContent={"center"}
      alignItems={"center"}
      onClick={() => router.push("/notification")}
      style={{ cursor: "pointer" }}
    >
      {unreadNotifications > 0 && (
        <>
          <Box
            position={"absolute"}
            width={45}
            height={45}
            bg={"primaryTransparent"}
            borderRadius={"full"}
            style={{ opacity: 0.5 }}
          />
          <Box
            position={"absolute"}
            width={55}
            height={55}
            bg={"primaryTransparentLight"}
            borderRadius={"full"}
          />
        </>
      )}
      <Image
        src={"/assets/icons/notificationIconPlain.svg"}
        alt={"Notifications"}
        width={23}
        height={23}
      />
      {unreadNotifications > 0 && (
        <Box
          border={`1px solid ${BASE_COLORS.primary}`}
          width={19}
          height={19}
          borderRadius={"full"}
          position={"absolute"}
          left={"55%"}
          top={"15%"}
          justifyContent={"center"}
          alignItems={"center"}
          backgroundColor={"primary"}
        >
          <Text size={12} color="white">
            {unreadNotifications}
          </Text>
        </Box>
      )}
    </NotificationHoverBox>
  );
};

const NotificationHoverBox = styled(Box)`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: ${BASE_COLORS.primaryTransparentLight};
  }
`;