import NextImage, { ImageProps as NextImageProps } from "next/image";
import styled from "styled-components";
import { BoxProps, systemStyles } from "../styled";
import css from "styled-jsx/css";
import { Merge } from "@/utils/typings";

export type ImageProps = Merge<NextImageProps, BoxProps>;

// without cursor: pointer
export const Image = styled(NextImage)<ImageProps>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${systemStyles}
`;
