"use client";
import { useNotificationContext } from "@/app/context/NotificationContext";
import { Box, Row, Text } from "../../../../components/styled";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";

export function CustomBottomNav() {
  const navItems = [
    {
      screen: "Home",
      icon: "/assets/icons/bottomTab/homeUnselected.svg",
      selectedIcon: "/assets/icons/bottomTab/homeSelected.svg",
      url: "/home/myschool",
    },
    {
      screen: "Search",
      icon: "/assets/icons/bottomTab/searchUnselected.svg",
      selectedIcon: "/assets/icons/bottomTab/searchSelected.svg",
      url: "/search",
    },
    {
      screen: "Create",
      icon: "/assets/icons/bottomTab/postsUnselected.svg",
      selectedIcon: "/assets/icons/bottomTab/postsSelected.svg",
      url: "/plus",
    },
    {
      screen: "Notification",
      icon: "/assets/icons/bottomTab/notificationsUnselected.svg",
      selectedIcon: "/assets/icons/bottomTab/notificationsSelected.svg",
      url: "/notification",
    },
    {
      screen: "Profile",
      icon: "/assets/icons/bottomTab/profileUnselected.svg",
      selectedIcon: "/assets/icons/bottomTab/profileSelected.svg",
      url: "/profile/overview",
    },
  ];

  const router = useRouter();
  const unreadNotifications = useNotificationContext();
  const [isSelectedIcon, setIsSelectedIcon] = useState("Home");
  const [isTransparent, setIsTransparent] = useState(false);

  useEffect(() => {
    let scrollTimeout: NodeJS.Timeout;

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      const scrollPercentage =
        (scrollPosition / (documentHeight - windowHeight)) * 100;

      setIsTransparent(scrollPercentage > 0.1);

      // Clear any previous timeout
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }

      // Set a timeout to revert the transparency if no scroll event occurs
      scrollTimeout = setTimeout(() => {
        setIsTransparent(false);
      }, 200); // Timeout duration (e.g., 300ms)
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box
      position="fixed"
      backgroundColor={"primary"}
      width="100%"
      py="sm"
      justifyContent="center"
      display={["flex", "none"]}
      style={{
        zIndex: 999,
        transition: "all 0.6s ease-in-out",
        opacity: isTransparent ? 0.7 : 1,
      }}
      bottom={0}
    >
      <Row justifyContent="space-around">
        {navItems.map((item) => (
          <Box
            onClick={() => {
              router.push(item.url);
              setIsSelectedIcon(item.screen);
            }}
            key={item.screen}
            position="relative"
            alignItems="center"
            gap="xs"
            width="20%"
            justifyContent="space-evenly"
          >
            <Image
              src={
                item.screen === isSelectedIcon ? item.selectedIcon : item.icon
              }
              alt={item.screen}
              width={26}
              height={26}
            />
            <Text size="12px" color="white">
              {item.screen}
            </Text>
            {item.screen === "Notification" && unreadNotifications > 0 && (
              <Box
                backgroundColor={
                  item.screen === isSelectedIcon ? "black" : "white"
                }
                position="absolute"
                bottom="72%"
                left="50%"
                width={20}
                height={20}
                justifyContent="center"
                alignItems="center"
                borderRadius="full"
              >
                <Text
                  color={item.screen === isSelectedIcon ? "white" : "primary"}
                  size="16px"
                >
                  {unreadNotifications}
                </Text>
              </Box>
            )}
          </Box>
        ))}
      </Row>
    </Box>
  );
}
