import { Box, CenterBox, Row, Text } from "@/components/styled";
import { BASE_FONTS } from "@/theme";
import React from "react";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { MenuItem } from "@/utils/types";

type DrawerItemProps = {
  item: MenuItem;
};

export const DrawerItem: React.FC<DrawerItemProps> = ({ item }) => {
  const router = useRouter();
  return (
    <CenterBox
      width={130}
      flexDirection="row"
      justifyContent="flex-start"
      gap="lg"
      key={item.id}
      alignItems="center"
      pb={"xl"}
      onClick={() => router.push(item.link)}
    >
      <Image src={item.icon} width={20} height={20} alt="" />
      <Text
        variant="p1"
        fontFamily={BASE_FONTS.semiBold}
        // @ts-ignore
        color="white !important"
      >
        {item.name}
      </Text>
    </CenterBox>
  );
};
