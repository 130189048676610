"use client";
import Link from "next/link";
import { useContext, useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  CenterBox,
  Column,
  Row,
  StyledBlinkBox,
  Text,
} from "../styled";
import Image from "next/image";
import Logo from "@/public/assets/icons/rushlink-logo.svg";
import PremiumLogo from "@/public/assets/images/svgimages/premiumLogo.svg";
import PremiumPlusLogo from "@/public/assets/images/svgimages/premiumPlusLogo.svg";
import styled from "styled-components";
import { BASE_COLORS } from "@/theme";
import { usePathname, useRouter } from "next/navigation";
import { TextWithIcon } from "./components/TextwithIcons";
import LogoutIcon from "@/public/assets/icons/web/logout.svg";
import { LogoutModal } from "./components/LogoutModal";
import { BouncyModal } from "../styled/BouncyModal";
import { useModal } from "@/hooks/useModal";
import { useUserV2 } from "@/hooks/useUserV2";
import PlusPrimaryIcon from "@/public/assets/icons/plus-primary.svg";
import { AppState } from "@/app/context/AppState";
import darkLogo from "@/public/assets/images/logo_rushlink_v3.png";
import lightLogo from "@/public/assets/images/svgimages/rushlinkLightModeLogo.svg";
import WhiteLogo from "@/public/assets/images/logo_rushlink.png";

type SideNavigationBarProps = {
  NavbarData: { icon: any; title: string; link: string }[];
};

export const SideNavigationBar = ({ NavbarData }: SideNavigationBarProps) => {
  const router = useRouter();
  const pathName = usePathname();
  const { isOpen, toggleModal } = useModal();
  const { user, isLoggedIn, logout } = useUserV2();
  const [activeLogo, setActiveLogo] = useState(Logo);

  const { theme } = useContext(AppState);

  const darkTheme = theme === "dark";

  useEffect(() => {
    if (!isLoggedIn) {
      setActiveLogo(Logo);
      return;
    }
    switch (user?.activePremiumPlan?.name) {
      case "Premium":
        setActiveLogo(PremiumLogo);
        break;
      case "Premium Plus":
        setActiveLogo(PremiumPlusLogo);
        break;
      default:
        setActiveLogo(Logo);
        break;
    }
  }, [isLoggedIn, user?.activePremiumPlan?.name]);



  return (
    <Column
      width="100%"
      height="100%"
      alignItems="center"
      pt="3xl"
      pb="xl"
      justifyContent="space-between"
    >
      <CenterBox>
        <Link href={"/home/for-you"}>
          <Image src={WhiteLogo} alt="Rushlink Logo" width={50} height={50} />
        </Link>
        <Column py="sm" gap="md">
          {NavbarData.filter(
            (item) =>
              (isLoggedIn && user?.activePremiumPlan) ||
              item.title !== "Support"
          ).map((item, index) => (
            <Button
              height={"50px"}
              key={index}
              backgroundColor={"primary"}
              border={`1px solid ${BASE_COLORS.primary}`}
              borderRadius={"full"}
            >
              <MenuItem active={pathName.startsWith(item.link)}>
                <TextWithIcon
                  icon={item.icon}
                  title={item.title}
                  link={item.link}
                />
              </MenuItem>
            </Button>
          ))}
          <StyledHoverPostBox
            onClick={() => {
              router.push("/plus");
            }}
            borderRadius={"full"}
          >
            <Button
              border={`1px solid ${BASE_COLORS.white}`}
              borderRadius={"full"}
              py={"md"}
              pl="2xl"
            >
              <Row
                justifyContent="start"
                alignItems="center"
                gap="3xl"
                width="100%"
              >
                <Image src={PlusPrimaryIcon} alt={""} height={25} />
                <Text
                  size={16}
                  letterSpacing={"-0.16px"}
                  weight="semiBold"
                  color="primary"
                >
                  Post
                </Text>
              </Row>
            </Button>
          </StyledHoverPostBox>
        </Column>
      </CenterBox>

      {isLoggedIn ? (
        <Button
          onClick={toggleModal}
          border={`1px solid ${BASE_COLORS.primary}`}
          backgroundColor={"primary"}
        >
          <TextWithIcon icon={LogoutIcon} title={"Logout"} link={""} />
          <Box backgroundColor={"grey"}>
            <BouncyModal
              isOpen={isOpen}
              onClose={toggleModal}
              height={"fit-content"}
              width={"25vw"}
              borderRadius="20px"
            >
              <LogoutModal logout={logout} toggleModal={toggleModal} />
            </BouncyModal>
          </Box>
        </Button>
      ) : null}
    </Column>
  );
};

type MenuItemProps = {
  active: boolean;
};

const MenuItem = styled(CenterBox)<MenuItemProps>`
  transition: all 0.3s ease-in-out;
  border-radius: 50px;
  background-color: ${({ active }) =>
    active ? BASE_COLORS.primary : "transparent"};
  color: ${({ active }) => (active ? BASE_COLORS.black : BASE_COLORS.white)};
  border: ${({ active }) =>
    active ? `1px solid ${BASE_COLORS.white}` : "none"};

  &:hover {
    transform: translateX(5px) scale(1.03);
    border: 1px solid ${BASE_COLORS.white};
  }
`;

export const StyledHoverPostBox = styled(Box)`
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(0.95);
    background-color: #f7f7f7;
    cursor: pointer;
  }
`;
