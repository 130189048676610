import { graphql } from "@/gql";
import { NetworkStatus, useQuery } from "@apollo/client";

export const WHO_TO_FOLLOW = graphql(`
  query WhoToFollow {
    whoToFollow {
      name
      logo
      type
      followerCount
      id
    }
  }
`);

export const useWhoToFollow = () => {
  const response = useQuery(WHO_TO_FOLLOW, {
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only",
  });

  return response;
};
