import Link from "next/link";
import { Box, CenterBox, Column, Row, Text } from "@/components/styled";
import { BASE_COLORS } from "@/theme";
import { useUserV2 } from "@/hooks/useUserV2";
import Image from "next/image";
import white_qr_code from "/assets/images/pngimages/white_qr_code.png";
import black_qr_code from "@/public/assets/images/pngimages/black_qr_code.png";
import { useContext } from "react";
import { AppState } from "@/app/context/AppState";

const GRADIENT_STYLE = {
  background:
    "linear-gradient(150deg, rgba(153, 52, 52, 1), rgba(255, 87, 87, 1))",
};

const LINK_STYLE = {
  textDecoration: "none",
};

export const Subscribe: React.FC = () => {
  const { user, isLoggedIn } = useUserV2();
  const { theme } = useContext(AppState);
  const darkTheme = theme === "dark";
  return (
    <Row gap={"10px"}>
      <Column
        width="75%"
        borderRadius="md"
        gap="sm"
        py="md"
        px="md"
        style={GRADIENT_STYLE}
      >
        {isLoggedIn && user?.activePremiumPlan ? (
          <Text color="white" size={14}>
            You are a Premium User
          </Text>
        ) : (
          <Text color="white" size={14}>
            Unlock Pro Features
          </Text>
        )}

        {isLoggedIn && user?.activePremiumPlan ? (
          <Text color="white" size={14}>
            Check your premium benefits
          </Text>
        ) : (
          <Text color="white" size={14}>
            Subscribe to unlock lots of new features.
          </Text>
        )}

        <Link href="/premium" style={LINK_STYLE}>
          <CenterBox
            border={`1px solid ${BASE_COLORS.white}`}
            borderRadius="full"
            px="sm"
            py="xs"
            width="90px"
            backgroundColor="primary"
          >
            {isLoggedIn && user?.activePremiumPlan ? (
              <Text color="white" fontSize={14}>
                Premium
              </Text>
            ) : (
              <Text color="white" fontSize={14}>
                Subscribe
              </Text>
            )}
          </CenterBox>
        </Link>
      </Column>
      <Link
        href={"https://apps.apple.com/in/app/rushlink/id6504420345"}
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        <Box
          backgroundImage={
            darkTheme
              ? "url('/assets/images/pngimages/black_qr_code.png')"
              : "url('/assets/images/pngimages/white_qr_code.png')"
          }
          width={"90%"}
          height={"70%"}
          backgroundRepeat={"no-repeat"}
          backgroundSize={"contain"}
          backgroundPosition={"center"}
          cursor="pointer"
        >
          <Text
            position={"relative"}
            top={"100%"}
            color="foreground"
            textAlign={"center"}
            fontSize={"12px"}
            paddingTop={"sm"}
            fontWeight={"bold"}
          >
            Download RushLink App
          </Text>
        </Box>
      </Link>
    </Row>
  );
};
