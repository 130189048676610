import { graphql } from "@/gql";
import { useQuery } from "@apollo/client";

const GET_PENDING_NOTIFICATIONS_DOCUMENT = graphql(`
  query GetPendingNotifications($input: FetchNotificationsInput!) {
    getPendingNotifications(input: $input) {
      id
      type
      isRead
      payload
      createdAt
    }
  }
`);

export const useGetPendingNotifications = () => {
  const response = useQuery(GET_PENDING_NOTIFICATIONS_DOCUMENT, {
    variables: { input: { skip: 0, take: 9999 } },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });
  return response;
};
