"use client";
import { BASE_COLORS } from "@/theme";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`


    @font-face {
        font-family: "DMSansRegular";
        src: url('/assets/fonts/DMSans-Regular.ttf') format("truetype");
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'DMSansSemiBold';
        src: url('/assets/fonts/DMSans-SemiBold.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'DMSansBold';
        src: url('/assets/fonts/DMSans-Bold.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    * {
    font-family: "DMSansRegular";

    }

    body {
        margin: 0;
     
    }

    * {
    box-sizing: border-box;
    ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    height: 0;
  }

    }

    .swiper {
  width: 240px;
  height: 320px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

.swiper-slide:nth-child(1n) {
  background-color: rgb(206, 17, 17);
}

.swiper-slide:nth-child(2n) {
  background-color: rgb(0, 140, 255);
}

.swiper-slide:nth-child(3n) {
  background-color: rgb(10, 184, 111);
}

.swiper-slide:nth-child(4n) {
  background-color: rgb(211, 122, 7);
}

.swiper-slide:nth-child(5n) {
  background-color: rgb(118, 163, 12);
}

.swiper-slide:nth-child(6n) {
  background-color: rgb(180, 10, 47);
}

.swiper-slide:nth-child(7n) {
  background-color: rgb(35, 99, 19);
}

.swiper-slide:nth-child(8n) {
  background-color: rgb(0, 68, 255);
}

.swiper-slide:nth-child(9n) {
  background-color: rgb(218, 12, 218);
}

.swiper-slide:nth-child(10n) {
  background-color: rgb(54, 94, 77);
}


.custom-mention textarea {
  background-color: red; /* Your desired background color */
  border-radius: 8px; /* Optional: rounded corners */
  padding: 5px; /* Optional: padding */
}

.custom-mention-item {
  padding: 8px; /* Optional: padding for each item */
  border-bottom: 1px solid #ddd; /* Optional: divider between items */
}


.p-mention-panel{
  background-color: ${BASE_COLORS.background};
  border: 1px solid ${BASE_COLORS.foreground};
  padding: 10px;
}

.p-mention-items	{
   padding-left: 10px;
   padding-right: 10px;
}

.p-mention-item	{
   padding: 10px;
}

.p-mention-item:hover	{
   background-color: rgba(255,87,87, 0.29);
}

`;
