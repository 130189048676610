// ProfileAndNotification.tsx
"use client";
import React from "react";
import { CenterBox, Row } from "@/components/styled";
import { useUser } from "@/hooks/useUser";
import { useModal } from "@/hooks/useModal";
import { OnboardingModal } from "@/components/OnboardingModal";
import { usePathname, useRouter } from "next/navigation";
import { useNotificationContext } from "@/app/context/NotificationContext";

import { ThemeSwitch } from "@/components/ThemeSwitch";
import { ProfileBox } from "./Components/ProfileBox";
import { NotificationBox } from "./Components/NotificationBox";
import { OnlineUsersCount } from "./Components/OnlineUsersCount";

type ProfileAndNotificationProps = {
  isLoggedIn: boolean;
};

export const ProfileAndNotification: React.FC<ProfileAndNotificationProps> = ({
  isLoggedIn,
}) => {
  const { toggleModal, isOpen } = useModal();
  const { data, refetch } = useUser();
  const router = useRouter();
  const unreadNotifications = useNotificationContext();
  const pathName = usePathname();

  React.useEffect(() => {
    refetch();
  }, [pathName, refetch]);

  return (
    <CenterBox gap={"md"}>
      <OnboardingModal onClose={toggleModal} isVisible={isOpen} />
      <Row justifyContent={"space-between"} width={"100%"}>
        <ProfileBox
          isLoggedIn={isLoggedIn}
          data={data}
          toggleModal={toggleModal}
          router={router}
        />
        <NotificationBox
          unreadNotifications={unreadNotifications}
          router={router}
        />
      </Row>
      <CenterBox
        width="100%"
        flexDirection="row"
        justifyContent="space-between"
      >
        <OnlineUsersCount />
        <ThemeSwitch />
      </CenterBox>
    </CenterBox>
  );
};
