import { Row } from "@/components/styled/Row";
import { Text } from "@/components/styled/Text";
import Image from "next/image";
import { CenterBox } from "@/components/styled/Box";
import styled from "styled-components";
import { BASE_SPACING } from "@/theme/elements/spacing";
import { BASE_COLORS } from "@/theme/elements/color";
import { useRouter } from "next/navigation";
import Link from "next/link";

const IconWithTextStyled = styled(Row)`
  width: 200px;
  padding: 14px;
  gap: 20px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

type TextWithIconProps = {
  icon: string;
  title: string;
  link: string;
  color?: "white" | "primary";
};

export const TextWithIcon: React.FC<TextWithIconProps> = ({
  icon,
  title,
  link,
  color = "white",
}) => {
  const router = useRouter();
  return (
    <Link href={link} style={{ textDecoration: "none" }}>
      <IconWithTextStyled>
        <Image src={icon} alt={""} />
        <Text
          size={16}
          letterSpacing={"-0.16px"}
          weight="semiBold"
          color={color}
        >
          {title}
        </Text>
      </IconWithTextStyled>
    </Link>
  );
};
