import React, { useEffect, useMemo } from "react";
import { Box, Text } from "@/components/styled";
import { useWhoToFollow } from "@/hooks/useWhoToFollow";
import { useUserV2 } from "@/hooks/useUserV2";
import { WhoToFollowCardV2 } from "./Components/WhoToFollowCardV2";
import styled from "styled-components";

const WhoToFollowGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px; // Adjust the gap as needed
  max-height: 40vh;
  overflow-y: scroll;
`;

export const WhoToFollow: React.FC = () => {
  const { data } = useWhoToFollow();
  const { isLoggedIn } = useUserV2();

  const followCards = useMemo(() => {
    if (!data?.whoToFollow) return null;

    const sortedWhoToFollow = [...data.whoToFollow].sort(
      (a, b) => (b.followerCount || 0) - (a.followerCount || 0)
    );

    return sortedWhoToFollow.map((item) => (
      <WhoToFollowCardV2
        key={item.id}
        name={item.name}
        image={item.logo || ""}
        following={item.followerCount}
        id={item.id}
      />
    ));
  }, [data?.whoToFollow]);

  if (!data?.whoToFollow || !isLoggedIn) return null;

  return (
    <Box>
      <Text size={20} pb={"md"}>
        Who to Follow
      </Text>
      <WhoToFollowGrid>
        {followCards}
      </WhoToFollowGrid>
    </Box>
  );
};