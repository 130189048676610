import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { API_HOST } from "./constants";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: `${API_HOST}`,
});
const authLink = setContext((_, { headers }) => {
  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : null;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
    },
    query: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    },
  },
});
