import { Box, Row } from "@/components/styled";
import { useRouter } from "next/navigation";

import Image from "next/image";
import { AllSocialMediaLogos } from "@/utils/constants";
import styled from "styled-components";
import Link from "next/link";
export const SocialMediaLogos = () => {
  const router = useRouter();
  return (
    <Row justifyContent={"space-between"}>
      {AllSocialMediaLogos.map((socialmedialogo, index) => (
        <Link href={socialmedialogo.routesTo} target="_blank" key={index}>
          <StyledHoverSMBox>
            <Image
              src={socialmedialogo.logo}
              alt={"logo"}
              width={25}
              height={25}
            />
          </StyledHoverSMBox>
        </Link>
      ))}
    </Row>
  );
};
export const StyledHoverSMBox = styled(Box)`
  &:hover {
    transform: rotate(10deg);
    cursor: pointer;
  }
`;
