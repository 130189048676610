"use client";

import React, { useContext, useState } from "react";
import { useRouter } from "next/navigation";
import toast from "react-hot-toast";
import { Box, Button, CenterBox, Text } from "@/components/styled";
import { BASE_COLORS } from "@/theme";
import { LoadingScreen } from "@/components/Loading";
import { Image } from "@/components/Image";
import { useUserV2 } from "@/hooks/useUserV2";
import { ActiveLogo } from "@/components/ActiveLogo";
import { AppState } from "@/app/context/AppState";
import lightLogo from "@/public/assets/images/svgimages/rushlinkicon_lightmode.svg"

import darkLogo from "@/public/assets/images/svgimages/rushlinkicon_darkmode.svg"
// Define prop types for the component
interface LogoutModalProps {
  toggleModal: () => void;
  logout?: () => void;
}

export const LogoutModal: React.FC<LogoutModalProps> = ({
  toggleModal,
  logout,
}) => {
  const router = useRouter();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const { theme } = useContext(AppState);
  const darkTheme = theme === "dark";

  // Handle the logout process
  const handleLogout = async () => {
    if (logout) logout();
    setIsLoggingOut(true);
    toast.promise(
      new Promise<string>((resolve, reject) => {
        setTimeout(() => {
          try {
            localStorage.removeItem("token");
            toggleModal();
            router.push("/home/myschool");
            resolve("Logged out successfully");
          } catch (error) {
            reject("Something went wrong");
          }
        }, 3000);
      }),
      {
        loading: "Logging out...",
        success: "Logged out successfully!",
        error: "Something went wrong",
      }
    );
  };

  // Show loading screen while logging out
  if (isLoggingOut) {
    return <LoadingScreen />;
  }

  return (
    <Box py="3xl" px="md" width="100%">
      <CenterBox width="100%"  >

       <Image src= {darkTheme ? darkLogo : lightLogo} alt={"logo"} width={40} height={40}/>
  
      </CenterBox>

      <Text size={["18px", "20px"]} weight="bold" pb="md" mt={"lg"}>
        Log out of RushLink?
      </Text>
      <Text size={["14px", "16px"]} color="grey" pb="2xl">
        You can always log back in at any time.
      </Text>
      <Box gap={15}>
        <ActionButton
          bg="primary"
          color="white"
          onClick={handleLogout}
          text="Log out"
        />
        <ActionButton
          bg="white"
          color="primary"
          onClick={() => {
            toggleModal();
          }}
          text="Cancel"
        />
      </Box>
    </Box>
  );
};

// Reusable button component for actions
interface ActionButtonProps {
  bg: string;
  color: string;
  onClick: () => void;
  text: string;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  bg,
  color,
  onClick,
  text,
}) => (
  <Button
    border={`1px solid ${BASE_COLORS.primary}`}
    borderRadius="2xl"
    bg={bg}
    px="md"
    py="xs"
    color={color}
    onClick={onClick}
  >
    <Text size={["14px", "16px"]} py="xs" color="black">
      {text}
    </Text>
  </Button>
);
