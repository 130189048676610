import React from "react";
import { PrimaryLightHoverBox, Box, Text } from "@/components/styled";
import { BASE_COLORS } from "@/theme";

type ProfileBoxProps = {
  isLoggedIn: boolean;
  data: any;
  toggleModal: () => void;
  router: any;
};

export const ProfileBox: React.FC<ProfileBoxProps> = ({
  isLoggedIn,
  data,
  toggleModal,
  router,
}) => {
  const truncateName = (name: string | undefined, maxLength = 28) => {
    if (!name) return "";
    return name.length > maxLength ? name.slice(0, maxLength) + "..." : name;
  };

  const handleRegisterLoginClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (!isLoggedIn) {
      toggleModal();
    }
  };

  return (
    <PrimaryLightHoverBox
      borderRadius="full"
      width="80%"
      py="sm"
      px="md"
      gap="lg"
      alignItems={"center"}
      justifyContent="center"
      border={`1px solid ${BASE_COLORS.primary}`}
      flexDirection="row"
      style={{ cursor: "pointer" }}
      onClick={() => router.push("/profile/overview")}
      flexShrink={5}
    >
      {isLoggedIn ? (
        <>
          <Box
            borderRadius="full"
            width="30px"
            height="30px"
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundColor="white"
            style={{
              backgroundImage: `url(${data?.me?.school?.logo})`,
            }}
          />
          <Box>
            <Text color="primary" fontSize={12}>
              {truncateName(data?.me?.school?.name)}
            </Text>
            <Text mt={"xxs"} fontSize={11}>
              {data?.me.school?.location}
            </Text>
          </Box>
        </>
      ) : (
        <Text
          onClick={handleRegisterLoginClick}
          style={{ cursor: "pointer" }}
          size={14}
          textAlign="center"
        >
          Register or Login to your account
        </Text>
      )}
    </PrimaryLightHoverBox>
  );
};
