import { useModal } from "@/hooks/useModal";
import { BouncyModal } from "../styled/BouncyModal";
import { useContext, useEffect, useState } from "react";
import { Box, Text } from "../styled";
import Image from "next/image";
import white_qr_code from "@/public/assets/images/pngimages/white_qr_code.png";
import black_qr_code from "@/public/assets/images/pngimages/black_qr_code.png";
import { useTheme } from "styled-components";
import { AppState } from "@/app/context/AppState";
type PopUpManagerProps = {
  ads?: any;
  delay: number;
  children: React.ReactNode;
};

export const PopUpManager = ({ ads, delay, children }: PopUpManagerProps) => {
  const { isOpen, toggleModal } = useModal();
  const [showModal, setShowModal] = useState(false);
  const { theme } = useContext(AppState);
  const darkTheme = theme === "dark";
  useEffect(() => {
    const hasShownModal = sessionStorage.getItem("hasShownModal");

    if (!hasShownModal) {
      const timer = setTimeout(() => {
        setShowModal(true);
        toggleModal();

        sessionStorage.setItem("hasShownModal", "true");
      }, delay);

      return () => clearTimeout(timer);
    }
  }, [delay]);

  return (
    <Box>
      {showModal && (
        <BouncyModal
          isOpen={isOpen}
          onClose={toggleModal}
          height={["40%", "75%"]}
          width={["85%", "60%"]}
        >
          <Box
            backgroundImage={
              darkTheme
                ? "url('/assets/images/pngimages/black_qr_code.png')"
                : "url('/assets/images/pngimages/white_qr_code.png')"
            }
            height={"70%"}
            backgroundRepeat={"no-repeat"}
            backgroundSize={"contain"}
            backgroundPosition={"center"}
            cursor="pointer"
            mt={"4xl"}
          ></Box>
          <Text color="foreground" size={[26, 40]} textAlign={"center"}>
            Read the <Text color="primary">Rush.</Text>
          </Text>
        </BouncyModal>
      )}
      {children}
    </Box>
  );
};
