"use client";
import React, {
  createContext,
  useMemo,
  useContext,
  useState,
  useEffect,
} from "react";
import { NotificationData } from "@/utils/constants";
import { useGetPendingNotifications } from "@/hooks/useGetPendingNotifications";
import { useIsLoggedIn } from "@/hooks/useIsLoggedIn";

// Create the context
const NotificationContext = createContext(0);

// Create a provider component
export const NotificationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isLoggedin } = useIsLoggedIn();
  const {
    data: PendingNotifications,
    loading,
    error,
    refetch,
  } = useGetPendingNotifications();

  useEffect(() => {
    if (!isLoggedin) return;
    const intervalId = setInterval(() => {
      refetch();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [isLoggedin, refetch]);

  const unreadNotifications = useMemo(() => {
    return PendingNotifications
      ? PendingNotifications.getPendingNotifications.filter(
          (notification) => !notification.isRead
        ).length
      : 0;
  }, [PendingNotifications]);

  if (!isLoggedin) return <>{children}</>;

  return (
    <NotificationContext.Provider value={unreadNotifications}>
      {children}
    </NotificationContext.Provider>
  );
};

// Custom hook to use the NotificationContext
export const useNotificationContext = () => {
  return useContext(NotificationContext);
};
