// OnlineUsersCount.tsx
import React, { useState, useEffect } from 'react';
import { Row, StyledBlinkBox, Text } from "@/components/styled";

export const OnlineUsersCount: React.FC = () => {
  const [onlineCount, setOnlineCount] = useState<number | null>(null);

  useEffect(() => {
    const updateOnlineCount = () => {
      const count = getOnlineUserCount();
      setOnlineCount(count);
    };

    updateOnlineCount();
    const intervalId = setInterval(updateOnlineCount, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const getOnlineUserCount = () => {
    const epochSeconds = Math.floor(Date.now() / 1000);
    const epochTenSeconds = Math.floor(epochSeconds / 10);
    const estHour = new Date().toLocaleString("en-US", {
      timeZone: "America/New_York",
      hour: "numeric",
      hour12: false,
    });
    const isDay = parseFloat(estHour) >= 6 && parseFloat(estHour) < 22;

    const getRandom = () => {
      let x = Math.sin(epochTenSeconds) * 10000;
      let rawRandom = x - Math.floor(x);
      return 0.1 + (rawRandom * 0.4);
    };

    let baseCount;
    if (isDay) {
      baseCount = 15000 + Math.floor(getRandom() * 5000);
    } else {
      baseCount = 8000 + Math.floor(getRandom() * 2000);
    }

    return Math.max(baseCount, isDay ? 15000 : 8000);
  }

  return (
    <Row gap={"md"} alignItems={"center"}>
      <StyledBlinkBox
        border={"1px solid green"}
        width={12}
        height={12}
        borderRadius={"full"}
        backgroundColor={"green"}
      />
      <Text color="foreground" size={14}>
        Online Now: {onlineCount !== null ? onlineCount.toLocaleString() : "Loading..."} People
      </Text>
    </Row>
  );
};