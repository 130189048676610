import { graphql } from "@/gql";
import { useMutation } from "@apollo/client";

export const FOLLOW_DOCUMENT = graphql(`
  mutation Follow($input: FollowActivityInput!) {
    follow(input: $input) {
      followableId
    }
  }
`);
export const useFollow = () => {
  const [follow, { data, loading, error }] = useMutation(FOLLOW_DOCUMENT);
  return {
    follow,
    loading,
    error,
    data,
  };
};
