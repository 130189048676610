"use client";
import { Box, CenterBox, Row, Text } from "@/components/styled";
import { useRouter } from "next/navigation";
import Image from "next/image";
import { DrawerItem } from "./components/DrawerItem";
import { useState, useEffect, useContext } from "react";
import { StyledHoverPostBox } from "@/components/SideNavigationBar";
import { useModal } from "@/hooks/useModal";
import { BouncyModal } from "@/components/styled/BouncyModal";
import { LogoutModal } from "@/components/SideNavigationBar/components/LogoutModal";
import Logo from "@/public/assets/icons/rushlink-logo.svg";
import PremiumLogo from "@/public/assets/images/svgimages/premiumLogo.svg";
import PremiumPlusLogo from "@/public/assets/images/svgimages/premiumPlusLogo.svg";
import { useUserV2 } from "@/hooks/useUserV2";
import { ThemeSwitch } from "@/components/ThemeSwitch";
import { Icon } from "@/components/Icon";
import { menuItems } from "@/utils/constants";
import { AppState } from "@/app/context/AppState";
import logoV2 from "@/public/assets/images/logo_rushlink_v2.png";
import logoV4 from "@/public/assets/images/logo_rushlink_v4.png";
import WhiteLogo from "@/public/assets/images/logo_rushlink.png";
import styled from "styled-components";
import { OnlineUsers } from "./components/OnlineUsers";

type HeaderProps = {
  showBackIcon?: boolean;
  showMenuIcon?: boolean;
};

export const MainHeader: React.FC<HeaderProps> = ({
  showBackIcon,
  showMenuIcon = true,
}) => {
  const { isLoggedIn, logout, user } = useUserV2();
  const [localIsLoggedIn, setLocalIsLoggedIn] = useState(isLoggedIn);
  const [Test, setTest] = useState<any>(null);
  import("react-simple-offcanvas").then((module) => setTest(module));
  const { isOpen: isLogoutModalOpen, toggleModal: toggleLogoutModal } =
    useModal();
  const router = useRouter();

  const { theme } = useContext(AppState);

  const darkTheme = theme === "dark";

  useEffect(() => {
    setLocalIsLoggedIn(isLoggedIn);
  }, [isLoggedIn]);

  const handleBackIcon = () => {
    router.back();
  };

  let menuLogo;
  if (user) {
    switch (user?.activePremiumPlan?.name) {
      case "Premium":
        menuLogo = PremiumLogo;
        break;
      case "Premium Plus":
        menuLogo = PremiumPlusLogo;
        break;
      default:
        menuLogo = Logo;
        break;
    }
  }

  return !Test ? null : (
    <Box
      width="100%"
      display={["flex", "none"]}
      position="fixed"
      top={0}
      zIndex={999}
      backgroundColor={darkTheme ? "black" : "white"}
    >
      <OnlineUsers />
      <Test.OffcanvasProvider>
        <Row
          justifyContent="space-between"
          alignItems={"center"}
          py={"sm"}
          px={"lg"}
        >
          <CenterBox>
            {showBackIcon && (
              <Image
                src="/assets/icons/backIcon.svg"
                alt=""
                width={30}
                height={30}
                onClick={handleBackIcon}
              />
            )}
            {showMenuIcon && (
              <Test.Trigger component="div">
                <Icon
                  color="foreground"
                  name={"hamburgerMenu"}
                  fontSize={"17px"}
                />
              </Test.Trigger>
            )}
          </CenterBox>
          <CenterBox ml={"xl"}>
            <Image
              src={darkTheme ? logoV4 : logoV2}
              alt="Rushlink Logo"
              width={50}
              height={40}
            />
          </CenterBox>
          <CenterBox>
            <ThemeSwitch />
          </CenterBox>
        </Row>

        {/* Off Canvas Menu */}
        <Test.Offcanvas
          position="left"
          title="Menu"
          backdrop={true}
          allowClickAway={true}
          className="simple-offcanvas"
        >
          <Box justifyContent="flex-end" px="lg" flexDirection={"row"}>
            <Image
              src="/assets/icons/closeIcon.svg"
              alt=""
              width={20}
              height={20}
            />
          </Box>
          <Box px="2xl">
            <Image src={WhiteLogo} alt="Rushlink Logo" width={55} height={55} />
            <Box mt="5xl" gap={5}>
              {menuItems.map((item, index) => (
                <DrawerItem item={item} key={index} />
              ))}
              {localIsLoggedIn && (
                <StyledHoverPostBox
                  onClick={() => router.push("/plus")}
                  border={"1px solid white"}
                  borderRadius={"4xl"}
                  backgroundColor={"white"}
                  alignContent={"center"}
                  textAlign={"center"}
                  py={"md"}
                >
                  <Text
                    size={16}
                    letterSpacing={"-0.16px"}
                    weight="semiBold"
                    color="primary"
                  >
                    Post
                  </Text>
                </StyledHoverPostBox>
              )}
            </Box>
          </Box>

          {localIsLoggedIn && (
            <Box
              bottom={30}
              position="absolute"
              left={24}
              flexDirection="row"
              alignItems={"center"}
              onClick={toggleLogoutModal}
            >
              <Image
                src="/assets/icons/logoutIcon.svg"
                alt=""
                width={20}
                height={20}
              />
              <Text variant="p1" color="white" ml="sm">
                Logout
              </Text>
            </Box>
          )}
          <BouncyModal
            isOpen={isLogoutModalOpen}
            onClose={toggleLogoutModal}
            height={"fit-content"}
            width={"90vw"}
            borderRadius="15px"
          >
            <LogoutModal logout={logout} toggleModal={toggleLogoutModal} />
          </BouncyModal>
        </Test.Offcanvas>
      </Test.OffcanvasProvider>
    </Box>
  );
};

const AnimatedSpan = styled.span`
  display: inline-block;
  transition: transform 0.25s ease-out, opacity 0.25s ease-out;
`;
