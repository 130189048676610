"use client";
import { ThemeProvider } from "styled-components";
import { useContext } from "react";
import { AppState } from "@/app/context/AppState";
import { DARK_THEME } from "./modes/dark";
import { LIGHT_THEME } from "./modes/light";

type AppThemeProviderProps = {
  children: React.ReactNode;
};

export const AppThemeProvider: React.FC<AppThemeProviderProps> = ({
  children,
}) => {
  const { theme } = useContext(AppState);

  return (
    <ThemeProvider theme={theme === "dark" ? DARK_THEME : LIGHT_THEME}>
      {children}
    </ThemeProvider>
  );
};
