"use client";
import { GlobalStyle } from "@/components/GlobalStyle";
import "./globals.css";
import "./styleIcoMoon.css";
import { AppThemeProvider } from "@/theme/AppThemeProvider";
import { Box, CenterBox, Row } from "@/components/styled";
import { BASE_COLORS } from "@/theme";
import { SideNavigationBar } from "@/components/SideNavigationBar";
import { RightSideBar } from "@/components/RightSideBar";
import { usePathname } from "next/navigation";
import { CustomBottomNav } from "@/components/styled/CustomBottomNav";
import { MainHeader } from "@/components/header/MainHeader";
import { ApolloProvider } from "@apollo/client";
import { client } from "@/utils/apolloClient";
import { Toaster } from "react-hot-toast";
import { NotificationProvider } from "./context/NotificationContext";

import { GA_ID, NavbarData } from "@/utils/constants";
import { GoogleAnalytics } from "@/components/GoogleAnalytics";
import StyledComponentsRegistry from "./registry";
import { AppStateProvider } from "./context/AppState";
import { PopUpManager } from "@/components/PopUpManager";
import { useEffect } from "react";
import { useViewStories } from "@/hooks/useViewStories";
import {
  BatchServiceEventEmitter,
  SendDataToServer,
} from "@/services/cachingService";

export const AppLayout = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  const pathName = usePathname();

  return (
    <html lang="en">
      <body style={{ minHeight: "100vh" }}>
        <AppStateProvider>
          <StyledComponentsRegistry>
            <ApolloProvider client={client}>
              <NotificationProvider>
                <AppThemeProvider>
                  <PopUpManager delay={5000}>
                    <GlobalStyle />
                    <CenterBox
                      backgroundColor="background"
                      minHeight={"100vh"}
                      justifyContent={"flex-start"}
                    >
                      <Box
                        maxWidth={["100%", "1300px"]}
                        minWidth={["100%", "1300px"]}
                        bg="background"
                      >
                        <Row width={"100%"}>
                          <Box
                            minWidth={["0%", "20%"]}
                            display={["none", "flex"]}
                            height={"100vh"}
                            position={"sticky"}
                            top={"0"}
                            bg="primary"
                            overflowY={"hidden"}
                          >
                            <SideNavigationBar NavbarData={NavbarData} />
                          </Box>
                          <Box
                            width={[
                              "100%",
                              `${
                                pathName.startsWith("/settings") ||
                                pathName.startsWith("/premium")
                                  ? "100%"
                                  : "55%"
                              }`,
                            ]}
                            // borderBottom={`1px solid ${BASE_COLORS.grey}`}
                            borderRight={`0.7px solid ${BASE_COLORS.lightGreyTransparent}`}
                            overflow="hidden"
                          >
                            <MainHeader />
                            <Box mb={["7xl", "none"]} mt={["6xl", "none"]}>
                              {children}
                            </Box>
                            <Box position="fixed" bottom={60}>
                              <CustomBottomNav />
                            </Box>
                          </Box>
                          {pathName.startsWith("/settings") ||
                          pathName.startsWith("/premium") ? null : (
                            <Box
                              width={["0%", "25%"]}
                              display={["none", "flex"]}
                              height={"100vh"}
                              position={"sticky"}
                              top={"0"}
                            >
                              <RightSideBar />
                            </Box>
                          )}
                        </Row>
                      </Box>
                    </CenterBox>
                  </PopUpManager>
                </AppThemeProvider>
                <Toaster />
              </NotificationProvider>
            </ApolloProvider>
          </StyledComponentsRegistry>
        </AppStateProvider>
        <div id="modal-root"></div>
        <GoogleAnalytics analyticsId={GA_ID || ""} />
      </body>
    </html>
  );
};
